/*
 * Scripts for /order
 */
import {addFlash} from "../base";
import map_init from "../smap";
import './ticket';

// show "ajax" loading loader before second step
// in some cases it cans block page
const formPrefix = document.querySelector('.container').dataset.formName || "parking_ticket";
const form = document.querySelector('form[name=' + formPrefix + ']');
if (form) {
    form.addEventListener('submit', () => {
        document.body.classList.add('loader-active');
    });

    let locationTextInput = form.querySelector('#' + formPrefix + '_locationText');
    let locationInput = form.querySelector('#' + formPrefix + '_location');

    locationTextInput.addEventListener('change', (event) => {
        // set location id by name or default cleare after change
        let loc_value = locations.find(loc => loc.text === locationTextInput.value);
        if (locationInput.value != loc_value) {
            locationInput.value = loc_value ? loc_value.value : '';
            // cancelable from parent event - usd in listenerGeometryClick
            locationInput.dispatchEvent(new Event('change', {bubbles: true, cancelable: event.cancelable ?? false}));
        }
    });

    locationInput.addEventListener('change', (e) => {
        let value = e.target.value;
        const priceInfo = document.getElementById('modalpriceinfo_button');
        if (priceInfo) {
            priceInfo.setAttribute('data-bs-target', '#modalpriceinfo_' + value);
            if (!value) {
                priceInfo.setAttribute('disabled', 'disabled');
            } else {
                priceInfo.removeAttribute('disabled');
            }
        }
    });

    function initPriceItemDropdown(clear = false) {
        // init dropdown
        let dropdownItem = form.querySelector('#' + formPrefix + '_priceItem');
        if (!dropdownItem) {
            return;
        }

        let dataStatus = dropdownItem.getAttribute('data-status');
        switch (dataStatus) {
            case 'empty-price-list':
                addFlash('danger', 'V tuto chvíli není možné zakoupit parkovné.');
                break;
            case 'load-error':
                addFlash('danger', 'Došlo k chybě při načítání ceníku. Zkuste to prosím později.');
                break;
        }

        // if (clear) {
        //     dropdownItem.querySelectorAll('option').forEach(el => {
        //         if (el.value) {
        //             dropdownItem.removeChild(el);
        //         }
        //     });
        // }
    }

    locationInput.addEventListener('change', () => initPriceItemDropdown(true));

    const plateModalEl = document.getElementById('modalspz');

    let plates = [];
    plateModalEl
        .querySelectorAll('.d-grid.gap-2 button')
        .forEach((button) => {
            button.addEventListener('click', () => {
                let plateInput = form.querySelector('#' + formPrefix + '_registrationNumber, #' + formPrefix + '_search');
                plateInput.value = button.dataset.plate;
                // plateModal.hide();
                //plateInput.dispatchEvent(new Event('change'));
                plateInput.dispatchEvent(new Event('change', {bubbles: true}));
            });

            plates.push({value: button.innerHTML, text: button.innerHTML})
        });

    var locationModalEl = document.getElementById('modalzones')
    let locations = [];
    locationModalEl.querySelectorAll('.d-grid.gap-2 button[data-id]')
        .forEach((button) => {
            button.addEventListener('click', () => {
                locationTextInput.value = button.querySelector('span').innerHTML;
                locationTextInput.dispatchEvent(new Event('change', {bubbles: true}));

                locationInput.value = button.getAttribute('data-id');
                locationInput.dispatchEvent(new Event('change', {bubbles: true}));
                // plateModal.hide();
            });

            locations.push({value: button.getAttribute('data-id'), text: button.querySelector('span').innerHTML})
        });

    let plateNumberListener = false;

    function initStreetParking() {
        if (plateNumberListener) {
            return;
        }

        let plateNumber = form.querySelector('#' + formPrefix + '_registrationNumber');
        if (!plateNumber) {
            return;
        }

        plateNumberListener = true;
        plateNumber.addEventListener('change', () => initPriceItemDropdown(true));
    }

    let searchNumberInputListener = false;

    function initExternalTicket() {
        if (searchNumberInputListener) {
            return;
        }

        let searchNumberInput = form.querySelector('#' + formPrefix + '_search');
        if (!searchNumberInput) {
            return;
        }

        let submitButton = form.querySelector('#' + formPrefix + '_submit');

        let lastValue = null;
        let timer = null;
        searchNumberInputListener = true;
        searchNumberInput.addEventListener('keyup', (e) => {
            if (lastValue != e.target.value && submitButton) {
                submitButton.setAttribute('disabled', 'disabled');
            }
            lastValue = e.target.value;

            if (timer !== null) {
                clearTimeout(timer);
                timer = null;
            }

            timer = setTimeout(() => {
                searchNumberInput.dispatchEvent(new Event('change', {bubbles: true}));
                if (submitButton) {
                    submitButton.removeAttribute('disabled');
                }
            }, 1000);
        });
    }

    // handle errors in pricelist load
    form.parentNode.__component.on('render:finished', () => {
        initExternalTicket()
        initStreetParking();
        initPriceItemDropdown();
    });
    initStreetParking();

    document.addEventListener('invalid', (function () {
        return function (e) {
            //prevent the browser from showing default error bubble / hint
            e.preventDefault();
            // optionally fire off some custom validation handler
            // myValidation();
        };
    })(), true);
}

// init map
map_init();
